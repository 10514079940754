import { initializeApp } from "firebase/app";
import {getFirestore, collection, addDoc ,updateDoc ,deleteDoc ,doc,setDoc,  } from '@firebase/firestore'
import {getAuth} from "firebase/auth"
import { getStorage,ref, uploadBytesResumable, getDownloadURL,uploadBytes ,deleteObject  } from "firebase/storage";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FREACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};


//Handle waiting to upload each file using promise
function uploadImagesToFirebase (imageFile) {
  return new Promise(function (resolve, reject) {

if(typeof(imageFile)=='object'){

  const storageRef = ref(storage, 'Project Images/' + `${imageFile.name}-${Math.random().toString(36).slice(2, 5)}`);
  const uploadTask = uploadBytesResumable(storageRef, imageFile);
  
  uploadTask.on('state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log(progress);

    },
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          reject(error.message)
          
          break;
        case 'storage/canceled':
          // User canceled the upload
          reject(error.message)
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          reject(error.serverResponse)
          break;
        default:
          reject("try again!")

      }
    },

    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        resolve({imgRef:storageRef._location.path_, url : downloadURL });
      });
    }
  );
}
 

})
}

const metadata = {
  contentType: 'video/mp4'
};
const uploadVideoToFirebase = (file) => {

  return new Promise((resolve, reject) => {

    // Upload file and metadata to the object 'video/xyz.mp4'
    const storageRef = ref(storage, 'Project Videos/' + `${file[0].name}-${Math.random().toString(36).slice(2, 5)}`);
    const uploadTask = uploadBytesResumable(storageRef, file[0], metadata);
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
       console.log(progress);

      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            reject(error.message)
            
            break;
          case 'storage/canceled':
            // User canceled the upload
            reject(error.message)
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            reject(error.serverResponse)
            break;
          default:
            reject("try again!")

        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve([{videoRef:storageRef._location.path_, url:downloadURL}]);
        });
      }
    );
  })

}

const saveProjectData = (object) => {
  return new Promise(async(resolve,reject)=>{
 // Add a new document in collection "cities"

const docID = `${Math.random().toString(36).slice(2, 10)}`;
console.log({...object, docID : docID})
await setDoc(doc(db, "Projects", docID), {...object, docID : docID});
alert("Done!!")
window.location.reload()

    
  
  })
}

const deleteFile = (fileRef) => {
  return new Promise((resolve,reject)=>{
    const storage = getStorage();

    // Create a reference to the file to delete
    const desertRef = ref(storage, `${fileRef}`);
    
    // Delete the file
    deleteObject(desertRef).then(() => {
      // File deleted successfully
      console.log("File deleted successfully")
      resolve("success")
    }).catch((error) => {
      // Uh-oh, an error occurred!
      reject(error)
      console.log(error)

    });
  })

}

const deleteProject = (id) => {
  console.log("deleting file")
  return new Promise((resolve,reject)=>{
     deleteDoc(doc(db, "Projects", `${id}`))
     .then(res=>{
      
     
      
    resolve("project deleted from firebase collection doc.")
     })
     .catch((error)=>{alert(error)})
  })
}

const updateData = async(id, object) => {
  
  return new Promise(async(resolve,reject)=>{
    console.log(`id == ${id}`)
    console.log(object)
    const docRef = doc(db, "Projects", `${id}`)
  
    // Set the "capital" field of the city 'DC'
    await updateDoc(docRef, object)
    .then(res=>{
      resolve(res)
    })
    .catch(error=>{
      reject(error)
    })
  })

  
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth()
export const db = getFirestore(app);
export const storage = getStorage(app);
export  {uploadImagesToFirebase,uploadVideoToFirebase,saveProjectData,deleteProject,updateData,deleteFile}; 
