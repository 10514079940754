import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { lazy, Suspense, useContext, useState } from "react";
import GlobalStyle from "./globalStyles";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "./components/Themes";
import Loading from "./subComponents/Loading";

import { AuthContext } from "./context/AuthContext";
import Login from "./New Back-End/Login";

//Components
const Main = lazy(() => import("./components/Main"));
const AboutPage = lazy(() => import("./components/AboutPage"));
const MySkillsPage = lazy(() => import("./components/MySkillsPage"));
const BlogPage = lazy(() => import("./components/BlogPage"));
const WorkPage = lazy(() => import("./components/WorkPage"));
const ContactMe = lazy(() => import("./components/ContactMe"));
const SoundBar = lazy(() => import("./subComponents/SoundBar"));

//back-end 
const Admin = lazy(() => import("./AdminFolder/Main"));
const Content = lazy(() => import("./AdminFolder/pages/Content/Content"));
const Projects = lazy(() => import("./AdminFolder/pages/Projects/Project"));
const Forms = lazy(() => import("./AdminFolder/pages/Contact Forms/ContactForm"));
const ContactUs = lazy(() => import("./AdminFolder/pages/AfterService/ContactUs"));



function App() {

  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  const location = useLocation();

  return (
    <>
      <GlobalStyle />

      <ThemeProvider theme={lightTheme}>
        <Suspense fallback={<Loading />}>

        <SoundBar />

          <AnimatePresence exitBeforeEnter>

            <Routes location={location} key={location.pathname}>

              <Route path="/login" element={<Login />} />

              <Route exact path="/" element={<Main/>} />

              <Route exact path="/about" element={<AboutPage/>} />

              <Route exact path="/blog" element={<BlogPage/>} />

              <Route exact path="/contact" element={<ContactMe/>} />

              <Route exact path="/work" element={<WorkPage/>} />

              <Route exact path="/skills" element={<MySkillsPage/>} />

              <Route exact path="/admin/dashboard" element={<RequireAuth><Admin/></RequireAuth>}/>
              <Route exact path="/admin/content" element={<RequireAuth><Content/></RequireAuth>}/>
              <Route exact path="/admin/projects" element={<RequireAuth><Projects/></RequireAuth>}/>
              <Route exact path="/admin/forms" element={<RequireAuth><Forms/></RequireAuth>}/>
              <Route exact path="/admin/keep-in-touch" element={<RequireAuth><ContactUs/></RequireAuth>}/>

              </Routes>
            
          </AnimatePresence>
        </Suspense>
      </ThemeProvider>
    </>
  );
}

export default App;
